<template>
	<div class="servicedetail">
		<!-- header -->
		<homePage :urlFlag="2"></homePage>
        <!-- banner -->
        <div class="container-fluid bannerBox bannerImg">
            <img src="@/assets/img_banner_service_4.png">
        </div>
        <div class="container newsList">
            <div class="row">
                <div class="col-md-9 article">
                    <h1>跨镜保税</h1>
                    <div class="articleDetail">
                        <p>四川丝路里与荷兰CMC贸易公司合资的专业运营跨境电商的服务平台。5年以上跨境电子商务平台运营成熟经验，提供完善的“采-运-储-销”一站式服务，做到真正的贸易供应链一体化闭环。</p>
                        <img src="@/assets/img_service_text_4.png" alt="">
                        <p>于2018年在中国（四川）自由贸易试验区成都青白江铁路港片区成立。其核心团队于2016年进入跨境电商行业，5年期间成功在青白江区铁路港片区完成首单跨境进口1210模式，跨境出口9610、<br/>9710、9810等进出口模式的操作。</p>
                        <p>是一支实战经验丰富、素质较高的专业操作团队，其负责人涉足跨境电商时间早经验丰富。作为成都青白江铁路片区较早进入的跨境电商企业，见证了跨境电商的快速发展，积累了丰富的快消品跨境<br/>电商操作经验，团队成员年轻而且充满朝气，具有创新意识并勇于迎接挑战。</p>
                        <p>2020年青白江铁路港片区，9810铁路出口清单模式首单由我司操作并成功通关放行，这意味着我司业务层面的创新从跨境进口1210模式到跨境出口B2C9610模式扩展到了跨境出口B2B领域，从跨境电商B2B出口“9710/9810 ”报关单模式，到跨境电<br/>商出口“9710/9810”清单模式的实际落地</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contactUs">
                        <div class="contactTitle">联系我们</div>
                        <div class="contactName">Fisher YU</div>
                        <div class="person-contact">
                            <div>
                                <div class="icon font_family icon-icon_phone_white"></div><div>servicepinkoi</div>
                            </div>
                            <div>
                                <div class="icon font_family icon-icon-mail"></div><div>srl-market@srleader.com</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <!-- 成功案例 -->
            <div class="cases">成功案例</div>
            <div class="allCases">
                <div class="case-box-hover"><img src="@/assets/img_service_4_1.png" alt=""><p>跨境出口（9610/9710/9810）</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_4_2.png" alt=""><p>跨境进口</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_4_3.png" alt=""><p>保税备货</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_4_4.png" alt=""><p>保税代发</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_4_5.png" alt=""><p>代收代付</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_4_6.png" alt=""><p>财税办理</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_4_7.png" alt=""><p>供应链金融</p></div>
            </div>
        </div>
		<afterbody></afterbody>
	</div>
</template>

<script>
// @ is an alias to /src
import homePage from "@/components/homePage/homePage.vue";
import afterbody from "@/components/afterbody/afterbody.vue";

export default {
	name: "servicebonded",
	components: {
		homePage,
		afterbody
	},
	data() {
		return {
		}
	},
	methods: {
	},
	mounted() {
	},
};
</script>

<style scoped>
@import "servicebonded.css";
</style>
